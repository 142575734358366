import { Dispatch, SetStateAction, useRef, useState} from "react";
import TextInput from "../shared/inputs/TextInput";
import Dropdown, { SingleStringSelectChangeValue } from "../shared/inputs/Dropdown";
import { CreateListForm } from "./CreateListModal";
import { categories } from "../../assets/categories";
import SwitchOptionButton from "../shared/inputs/SwitchOptionButton";
import { fallbackImage } from "../../constants/urls";
import usePreviewImage from "../../hooks/usePreviewImage";
import { Categories } from "../../types/SharedTypes";
import MediaSelectorModal from "../shared/modals/MediaSelectorModal";
import { ThumbnailOption } from "./CreateCollectionTab";

type Props = {
  form: CreateListForm;
  setForm: Dispatch<SetStateAction<CreateListForm>>;
  setNewImage: (file?: File) => void;
  forCollection?: string;
  listId?: string;
}

const CreateListTab = ({form, setForm, setNewImage, forCollection, listId}: Props) => {
  
  const {clearImage, handleImage, imagePreview} = usePreviewImage(setNewImage);

  const [showMediaModal, setShowMediaModal] = useState(false);
  const originalImageRef = useRef<string|undefined>(form.image ? form.image : undefined);
  
  const currentCategory = categories.find(opt => opt.value === form.category) || null;
  
  const updateCategory = (data: SingleStringSelectChangeValue) => {
    if (!data) return;

    setForm((prev) => ({
      ...prev,
      category: data.value as Categories,
    }));
  };
  
  return (
    <>
      {showMediaModal &&
        <MediaSelectorModal
          postId={listId}
          imagePreview={imagePreview}
          clearPreviewImage={clearImage}
          originalImage={originalImageRef.current}
          currentImage={form.image}
          setNewImage={(image) => setForm(prev => ({...prev, image}))}
          setModalVisible={setShowMediaModal}
          handleImage={handleImage}
          closeOnSelect
        />
      }
      <section className="padding">
        <div className='item-list large'>
          <TextInput
            value={form.title}
            onChange={(val) => setForm(prev => ({...prev, title: val}))}
            label='title'
            id='titleinput'
            required
          />
          <TextInput 
            value={form.description}
            onChange={(val) => setForm(prev => ({...prev, description: val}))}
            label='description'
            id='descriptioninput'
            required
          />
          <div className="item-list">
            <div className="item-row xl">
              <ThumbnailOption
                imageSrc={imagePreview || form.image || fallbackImage}
                imagePreview={imagePreview}
                clearImage={clearImage}
                setShowMediaModal={setShowMediaModal}
              />
              <div className="divider-line-vertical or" />
              <TextInput 
                value={form.thumbnailEntry ? `${form.thumbnailEntry}` : ''}
                placeholder={"Default 3"}
                onChange={(val) => setForm(prev => ({...prev, thumbnailEntry: Number(val)}))}
                label='Use image from list item in position'
                id='thumbEntryinput'
              />
            </div>
          </div>
          {!forCollection &&
            <div className="item-list med">
              <label aria-required={true} className="subtitle-medium indent">{"Category"}</label>
              <Dropdown 
                value={currentCategory}
                options={categories}
                onChange={updateCategory}
                error={false}
                placeholder={'Select category...'}
                openToTop
                isSearchable={false}
              />
            </div>
          }
          {!forCollection &&
            <SwitchOptionButton
              label={"Visibility"}
              required
              options={[
                {
                  text: "Public",
                  onClick: () => setForm(prev => ({...prev, isPublic: true})),
                  selected: form.isPublic
                },
                {
                  text: "Personal",
                  onClick: () => setForm(prev => ({...prev, isPublic: false})),
                  selected: !form.isPublic
                }
              ]} 
            />
          }
          <SwitchOptionButton
            label={"List Type"}
            required
            note={form.ordered 
              ? "Items in ordered lists are numbered."
              : "Items in unordered lists are not numbered, but do retain the order you set."
            }
            options={[
              {
                text: "Ordered",
                onClick: () => setForm(prev => ({...prev, ordered: true})),
                selected: form.ordered
              },
              {
                text: "Unordered",
                onClick: () => setForm(prev => ({...prev, ordered: false})),
                selected: !form.ordered
              }
            ]} 
          />
          <SwitchOptionButton
            disabled={!form.ordered}
            label={"Order"}
            required
            note={form.ascending 
              ? "Ascending puts the 1st place item at the top." 
              : "Descending puts the 1st place item at the bottom."
            }
            options={[
              {
                text: "Ascending",
                onClick: () => setForm(prev => ({...prev, ascending: true})),
                selected: form.ascending
              },
              {
                text: "Descending",
                onClick: () => setForm(prev => ({...prev, ascending: false})),
                selected: !form.ascending
              }
            ]} 
          />
          {/* <button className="text item-row large" onClick={() => setMoreOptionsVisible(!moreOptionsVisible)}>
            More Options
            <div className="icon small">
              {moreOptionsVisible ? <ArrowHeadUpIcon /> : <ArrowHeadDownIcon />}
            </div>
          </button>
          {moreOptionsVisible &&
            <p>stuff</p>
          } */}
        </div>
      </section>
    </>
  )
}

export default CreateListTab;