import { useNavigate, useParams } from "react-router";
import PageWrapper from "./PageWrapper";
import { Dispatch, SetStateAction, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { DecoratedList, DecoratedListItem, ListItemType } from "../types/SharedTypes";
import { ArrowHeadLeftIcon, FeedIcon, LikeIcon, LikeIconSolid, LinkIcon, OptionsHorizontalIcon, SaveIcon, SaveIconSolid, DeleteIcon, ShareIcon, PeakLogo, InfoIcon, StyledOneIcon } from "../assets/icons";
import useFullCustomTheme from "../hooks/useFullCustomTheme";
import { ToastContext } from "../contexts/ToastContext";
import { basicDateFormat, checkLinkAgainstWhitelist, hslToHex, sortListByPosition } from "../tools/Helpers";
import { useCreateLike, useCreateSave, useRemoveLike, useRemoveSave } from "../api/data-access/generalHooks";
import { getCategory } from "../assets/categories";
import GeneralConfirmationModal from "../components/shared/modals/GeneralConfirmationModal";
import { useDocumentTitle } from "../hooks/useDocumentTitle";
import { useGetListById, useGetListByIdOpen } from "../api/data-access";
import { UseQueryResult } from "@tanstack/react-query";
import ContextMenu, { ContextMenuContent } from "../components/shared/modals/ContextMenu";
import LightboxModal from "../components/shared/modals/LightboxModal";
import useDisplayServerError from "../hooks/useDisplayServerError";
import { useGenerateListImage } from "../hooks/useGenerateListImage";
import Modal from "../components/Modal";
import useAuthentication from "../hooks/useAuthentication";
import TextInput from "../components/shared/inputs/TextInput";

export type ActiveIndex = number | null;

const List = ({auth = true}: {auth?: boolean}) => {

  //reset scroll to top when load
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return auth ? <AuthListContent /> : <NoAuthListContent />;
}

const NoAuthListContent = () => {
  const {id} = useParams();
  const list = useGetListByIdOpen(id || '');
  
  return <ListContent list={list} />
}

const AuthListContent = () => {
  const {id} = useParams();
  const list = useGetListById(id || '');
  
  return <ListContent list={list} />
}

type ListContentProps = {
  list: UseQueryResult<DecoratedList, Error>;
}

const ListContent = ({list}: ListContentProps) => {
  
  const [searchParams] = useSearchParams();
  
  const {isAuthenticated} = useAuthentication();
  
  const [activeIndex, setActiveIndex] = useState<ActiveIndex>(null);
  
  useFullCustomTheme(list.data?.theme || searchParams.get('theme')?.toString());
  useDocumentTitle(list.data?.title || "List", list.isSuccess);
  
  const onList = useMemo(() => list.isSuccess && sortListByPosition<ListItemType | DecoratedListItem<ListItemType>>(list.data.onList, list.data.ascending), [list]);

  if (list.isPending || !onList) return null;
  else if (list.isError || !list.isSuccess) return <p>Error</p>;
  return (
    <PageWrapper padding={false}>
      <ListHeader list={list.data} preventLike={!isAuthenticated || list.data.isAuth} allowEdit={list.data.isAuth && !list.data.isAutoGenerated } />
      <div className="list-table graphic">
        {onList.map((l, i) =>
          <ListItem
            key={l._id}
            preventSave={!isAuthenticated || list.data.isAuth}
            listItem={l}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            listItemIndex={i}
            ordered={list.data.ordered}
            ascending={list.data.ascending}
            isAutoGenerated={list.data.isAutoGenerated}
          /> 
        )}
      </div>
    </PageWrapper>
  )
}

type ListHeaderProps = {
  list: DecoratedList;
  preventLike: boolean;
  allowEdit: boolean;
}

const ListHeader = ({list, preventLike, allowEdit}: ListHeaderProps) => {
  
  const navigate = useNavigate();
  const [listLiked, setListLiked] = useState(false);
  const [shareModalVisible, setShareModalVisible] = useState(false);
  
  const createLike = useCreateLike();
  const removeLike = useRemoveLike();

  const {createAndDownload} = useGenerateListImage();

  const wrapperRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLDivElement>(null);
  const parentRef = useRef<HTMLDivElement>(null);

  const scaleToFitScreen = () => {
    if (wrapperRef.current && imageRef.current && parentRef.current) {
      const scaleFactor = (wrapperRef.current.offsetWidth - 28) / imageRef.current.offsetWidth;
      imageRef.current.style.transform = `scale(${scaleFactor})`;
      
      const scaledWidth = imageRef.current.offsetWidth * scaleFactor;
      const scaledHeight = imageRef.current.offsetHeight * scaleFactor;

      parentRef.current.style.width = `${scaledWidth}px`;
      parentRef.current.style.height = `${scaledHeight}px`;
    }
  };
  
  useEffect(() => {
    setListLiked(!!list.likedByAuthUser);
  }, [list]);

  useEffect(() => {
    window.addEventListener('resize', scaleToFitScreen);
    return () => window.removeEventListener('resize', scaleToFitScreen);
  }, []);

  useEffect(() => {
    if (shareModalVisible) scaleToFitScreen();
  }, [shareModalVisible]);
  
  const handleToggleLike = () => {
    if (listLiked) {
      removeLike.mutate(list._id);
      setListLiked(false);
    }
    else {
      createLike.mutate(list._id);
      setListLiked(true);
    }
  }

  const handleShare = async () => {
    
    if (!imageRef.current) return;
    const transform = imageRef.current.style.transform;
    imageRef.current.style.transform = "unset";
    await createAndDownload(
      "sharePoint",
      `https://peaklist.app/list/${list._id}`,
      list.isPublic,
      `${list.title} - ${list.author.username}`,
      list.theme
    );
    imageRef.current.style.transform = transform;
  }

  const dummyItemProps = {
    preventSave: true,
    activeIndex: null,
    setActiveIndex: () => null,
    ordered: list.ordered,
    ascending: true,
    isAutoGenerated: false,
  }
  
  return (
    <>
      {shareModalVisible &&
        <Modal 
          setModalVisible={setShareModalVisible}
          modalTitle="Share"
          scrollRef={wrapperRef}
          removePadding
          scroll
          controls
          children={
            <div className="item-list large padding-small">
              <div className="item-list">
                {!list.isPublic && 
                  <div className="tile item-row padding-small">
                    <div className="icon-display small">
                      <InfoIcon />
                    </div>
                    <p className="body">This list is not public so sharing is limited!</p>
                  </div>
                }
                <p className="subtitle-large weight-bold secondary indent">Share Link</p>
                <TextInput
                  id={"copyLink"}
                  value={`https://peaklist.app/list/${list._id}`}
                  onChange={(val) => null}
                  secondary
                  copyButton
                />
              </div>
              <div className="item-list">
              <p className="subtitle-large weight-bold secondary indent">Share Image</p>
              <button className="primary" onClick={handleShare}>Download Image</button>
                <div ref={parentRef} className="padding-top-small">
                  <div ref={imageRef} className="share-preview border" data-outline={true} data-themed={true} id={"sharePoint"}>
                    <div className="right-item-layout padding-large">
                      <div className="item-list">
                        <div className="logo">
                          <PeakLogo crossorigin />
                        </div>
                        <p className="subtitle-large secondary">https://peaklist.app</p>
                        <p className="subtitle-large secondary">{basicDateFormat(list.createdAt)}</p>
                      </div>
                    </div>
                    <div className="padding item-list remove-padding-top">
                      <div className="right-item-layout">
                        <div className="item-row">
                          <p className="heading-medium ellipsis indent">{list.title}</p>
                          <p className="heading-medium weight-light secondary">@{list.author.username}</p>
                        </div>
                        <div className="item-row padding-right-small">
                          <div className="tag" data-themed={true} data-secondary={true}>
                            <p className="subtitle-medium header">{getCategory(list.category)}</p>
                          </div>
                          <p className="subtitle-large secondary">5/{list.onList.length} Items</p>
                        </div>
                      </div>
                      <div className="list-table graphic">
                        <div className="item-row no-gap">
                          <ListItem
                            {...dummyItemProps}
                            listItem={list.onList[0]}
                            listItemIndex={0}
                          />
                          <div className="item-list no-gap">
                            {list.onList.map((item, i) => i > 0 && i < 5 &&
                              <ListItem
                                key={i}
                                {...dummyItemProps}
                                listItem={item}
                                listItemIndex={i}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        />
      }
      <section className="item-list padding">
        <div className="right-item-layout">
          <div className="item-row">
            <a onClick={() => navigate(-1)} className="icon small">
              <ArrowHeadLeftIcon />
            </a>
            <div className="tag" data-themed={true}>
              <p className="subtitle-medium header">{getCategory(list.category)}</p>
            </div>
          </div>
          {!list.isAuth &&
            <Link to={`/user/${list.author.username}`}>
              <article className="item-row center-vertical card-header">
                <div className="item-list">
                  <h3 className="subtitle-large">{list.author.displayName}</h3>
                </div>
                <div className="user-avatar small">
                  <img src={list.author.picture} />
                </div>
              </article>
            </Link>
          }
          {allowEdit &&
            <Link to={`/creator/${list._id}`}>
              <button className="primary short">Edit</button>
            </Link>
          }
        </div>
        <div className="item-list small">
          <div className="item-row">
            <p className="subtitle-medium secondary">List</p>
            <p className="subtitle-medium">{basicDateFormat(list.createdAt)}</p>
          </div>
          {list.parentCollection &&
            <div className="item-row">
              <p className="subtitle-medium secondary">Collection</p>
              <Link className="underline" to={`/collection/${list.parentCollection}`}> <p className="subtitle-medium">View</p></Link>
            </div>
          }
          <div className="item-row">
            <p className="subtitle-medium secondary">Views</p>
            <p className="subtitle-medium">{list.views || 0}</p>
          </div>
        </div>
        <div className="right-item-layout center-horizontal">
          <h1 className="heading-large">{list.title}</h1>
          <div className="item-row">
            <button className="icon" onClick={() => setShareModalVisible(true)}>
              <ShareIcon />
            </button>
            {!preventLike &&
              <button className="icon" onClick={handleToggleLike}>
                {listLiked ? <LikeIconSolid /> : <LikeIcon />}
              </button>
            }
          </div>
        </div>
      </section>
    </>
  )
}

type ListItemProps = {
  activeIndex: ActiveIndex;
  listItem: ListItemType | DecoratedListItem<ListItemType>;
  setActiveIndex: Dispatch<SetStateAction<ActiveIndex>>;
  listItemIndex: number;
  ordered?: boolean;
  ascending?: boolean;
  preventSave?: boolean;
  customControl?: React.ReactNode;
  isAutoGenerated?: boolean;
  noHero?: boolean;
};

export const ListItem = ({listItem, activeIndex, setActiveIndex, listItemIndex, ordered = true, ascending = true, preventSave, customControl, isAutoGenerated, noHero}: ListItemProps) => {
  
  const {requestToast} = useContext(ToastContext);
  const navigate = useNavigate();
  const {handleError} = useDisplayServerError();
  
  const [saved, setSaved] = useState('savedByAuthUser' in listItem ? listItem.savedByAuthUser : false);
  const [saveListItemOptionsMenuVisible, setSaveListItemOptionsMenuVisible] = useState(false);
  const [imageModalVisible, setImageModalVisible] = useState(false);
  
  const saveItem = useCreateSave();
  const unsaveItem = useRemoveSave();
  
  const handleSave = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    
    if (saved) {
      unsaveItem.mutate(listItem._id, {
        onSuccess: () => {
          setSaved(false);
          requestToast('Removed from saved.', 'success');
        },
        onError: (err) => handleError(err)
      });
    }
    else {
      saveItem.mutate(listItem._id, {
        onSuccess: () => {
          setSaved(true);
          requestToast('Saved for later.', 'success');
        },
        onError: (err) => handleError(err)
      });
    }
    setSaved(prev => !prev);
  }
  
  const handleRemoveSavedItem = () => {
    if (!listItem.meta?.originalListItem) return;
    unsaveItem.mutate(listItem.meta?.originalListItem, {
      onSuccess: () => requestToast('Removed item.'),
      onError: () => requestToast('Failed to remove.', 'error')
    });
  }
  
  const savedItemMenuData: ContextMenuContent = {
    title: "Saved Item Options",
    focusedItem: false,
    menuContent: [
      {
        title: "Go to Original List",
        icon: FeedIcon,
        handleClick: () => navigate(`/list/${listItem.meta?.originalList}`),
        visible: !!listItem.meta?.originalList
      },
      {
        title: "Remove Save",
        icon: DeleteIcon,
        alert: true,
        handleClick: handleRemoveSavedItem
      }
    ]
  }
  
  return (
    <>
      {saveListItemOptionsMenuVisible && <ContextMenu menuData={savedItemMenuData} setModalVisible={setSaveListItemOptionsMenuVisible} />}
      {imageModalVisible &&
        <LightboxModal closeModal={() => setImageModalVisible(false)} imageUrl={listItem.image} title={listItem.title} />
      }
      <div 
        className={`table-item item-list large ${ascending ? 'bottom-border' : 'top-border' }`}
        data-hero={!noHero && ordered && listItem.position === 1}
        data-thumb={!!listItem.image}
        data-numbered={ordered}
        key={listItem._id}
        role={"button"}
        onClick={() => setActiveIndex(prev => prev === listItemIndex ? null : listItemIndex)}
      >
        {ordered && (listItem.position === 1 && !noHero 
          ? <div className="list-position"><StyledOneIcon /></div>
          : <p className="heading-small weight-light center list-position">{listItem.position}</p>
        )}
        <div className="item-list position-relative">
          <div className="item-row large min-width">
            {listItem.image &&
              <button className="empty darkness-hover" onClick={(e) => {
                e.stopPropagation();
                setImageModalVisible(true);
              }}>
                <div className="thumb large">
                  <img crossOrigin="anonymous" src={listItem.image + "?cache_bypass=1"} />
                </div>
              </button>
            }
            <div className="right-item-layout full-width">
              <div className="item-list min-width">
                <p className="heading-small weight-light ellipsis-block">{listItem.title}</p>
                <p className="subtitle-medium ellipsis secondary">{listItem.subtitle}</p>
              </div>
              {customControl && customControl}
              {!customControl && !preventSave && activeIndex === listItemIndex && !isAutoGenerated &&
                <button className="icon-text-vert" onClick={handleSave}>
                  {saved ? <SaveIconSolid /> : <SaveIcon />}
                  <span className="subtitle-small weight-light">Save{+ saved ? "d" : ''}</span>
                </button>
              }
              {!customControl && activeIndex === listItemIndex && isAutoGenerated &&
                <button className="icon-text-vert" onClick={(e) => {
                  e.stopPropagation();
                  setSaveListItemOptionsMenuVisible(true);
                }}>
                  <OptionsHorizontalIcon />
                  <span className="subtitle-small weight-light">Options</span>
                </button>
              }
            </div>
          </div>
        </div>
        <div className="table-item-info-container" aria-current={activeIndex === listItemIndex}>
          <div>
            <ListItemControls item={listItem} />
          </div>
        </div>
      </div>
    </>
  )
}

type ListItemControlsProps = {
  item: ListItemType;
}

const ListItemControls = ({item}: ListItemControlsProps) => {
  
  const [linkModalVisible, setLinkModalVisible] = useState(false);
  
  if (!item.link && !item.authorNotes) return <></>;
  return (
    <>
      {linkModalVisible &&
        <GeneralConfirmationModal setModalVisible={setLinkModalVisible} onConfirm={() => window.open(item.link, '_blank')}>
          <div className="item-list xl">
            <div className="item-list small">
              <p className="heading-small spaced">By clicking this link you'll be leaving Peaklist.</p>
              <p className="subtitle-large spaced secondary">We can't guarantee anything good will come out of this.</p>
            </div>
            <a href={item.link} rel="noopener" target="_blank" className="link">
              <p className="subtitle-large weight-bold spaced break-anywhere">{item.link}</p>
            </a>
          </div>
        </GeneralConfirmationModal>
      }
      <div className="item-list large table-controls min-width">
        {item.link && 
          <button className="text icon-text small" onClick={() => checkLinkAgainstWhitelist(item.link, setLinkModalVisible)}>
            <LinkIcon />
            <p className="subtitle-medium weight-bold ellipsis">{item.link}</p>
          </button>
        }
        {item.authorNotes &&
          <div className="item-list small">
            <p className="subtitle-small">Notes</p>
            <p className="subtitle-small weight-light spaced">{item.authorNotes}</p>
          </div>
        }
      </div>
    </>
  )
}

export default List;