import {useState, useContext, useEffect, DragEvent} from 'react';
import { ToastContext } from '../../../contexts/ToastContext';
import { UploadImageIcon } from '../../../assets/icons';

type Props = {
  title: string;
  uniqueID: string;
  mimeTypes: string[];
  getFiles: (files: File[]) => void;
  uploadMultiple?: boolean;
  label?: string;
  subtitle?: string;
  icon?: JSX.Element;
  hero?: boolean;
  selectFromMediaBrowser?: boolean;
  required?: boolean;
};

const UploadInput = ({
  title,
  label,
  uniqueID,
  mimeTypes,
  getFiles,
  uploadMultiple,
  subtitle,
  icon,
  hero
}: Props) => {

  const [dragOver, setDragOver] = useState(false);
  const [inputAccepts, setInputAccepts] = useState('');

  const {requestToast} = useContext(ToastContext);

  const handleFileDrop = (ev: DragEvent<HTMLLabelElement> | DragEvent<HTMLInputElement>) => {
    ev.preventDefault();
    const fileArr = [];
    if (ev.dataTransfer.items) {
      for (let i = 0; i < ev.dataTransfer.items.length; i++) {
        const file = ev.dataTransfer.items[i].getAsFile();
        if (!file) continue;
        const valid = verifyMimeTypes(file);
        if (!valid) {
          setDragOver(false);
          return;
        }
        if (ev.dataTransfer.items[i].kind === 'file') {
          fileArr.push(file);
        }
      }
    }
    fileArr.length > 0 && sendFiles(fileArr.filter(file => !!file));
  };

  const verifyMimeTypes = (file: File) => {
    const incomingMime = file.type;
    let matched = false;
    //loop through allowed mimeTypes and reject if it doesnt match
    for (const mime of mimeTypes) {
      const mimeSplit = mime.split('/');
      const incomingMimeSplit = incomingMime.split('/');

      if (
        mimeSplit[0] === incomingMimeSplit[0] &&
        (mimeSplit[1] === incomingMimeSplit[1] || incomingMimeSplit[1] === `x-${mimeSplit[1]}`)
      ) {
        matched = true;
        return matched;
      }
    }
    if (!matched) {
      requestToast('File type not supported.', 'warn');
    }
    return matched;
  };

  const handleDragOver = (ev: DragEvent<HTMLLabelElement>) => {
    ev.preventDefault();
  };
  const sendFiles = (files: FileList | File[] | null) => {
    let valid = false;
    if (!files) return;
    for (const file of files) {
      if (!file) continue;
      verifyMimeTypes(file) && (valid = true);
    }

    setDragOver(false);
    if (!valid) return;
    getFiles([...files]);
  };

  useEffect(() => {
    setInputAccepts(mimeTypes.join(', '));
  }, [mimeTypes]);

  return (
    <>
      <div className={`file-input min-width item-list small ${hero ? 'hero' : ''}`}>
        <div className="file-input-button">
          <label
            htmlFor={uniqueID}
            className="file-input-large"
            onDrop={handleFileDrop}
            onDragOver={handleDragOver}
            onDragEnter={() => setDragOver(true)}
            onDragExit={() => setDragOver(false)}
            data-drag-over={dragOver}
          >
            {icon ? icon : <UploadImageIcon />}
            <div className="item-list small">
              <span className={`${hero ? 'heading-medium' : ''}`}>
                {dragOver ? "Dropping file.." : title}
              </span>
              {subtitle && <span className="heading-small weight-light">{subtitle}</span>}
            </div>
          </label>
        </div>
        <input
          className="droppable"
          type="file"
          id={uniqueID}
          accept={inputAccepts}
          onDrop={handleFileDrop}
          onChange={(ev) => sendFiles(ev.target.files)}
          multiple={uploadMultiple}
        />
        <label className="ellipsis secondary">{label || ''}</label>
      </div>
    </>
  );
};
export default UploadInput;
