import { DeleteIcon, EditIcon, GrabIcon, HiddenIcon, VisibleIcon } from "../../assets/icons";
import { CREATOR_PAGES, CreatorContext } from "../../contexts/CreatorContext";
import { CreatorListItemType, CreatorListType } from "../../types/SharedTypes";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { Draggable, DraggableProvidedDragHandleProps, Droppable} from 'react-beautiful-dnd';
import { DragPosition, ListItemCreatorControls } from "../ListCreator";
import { ToastContext } from "../../contexts/ToastContext";
import GeneralConfirmationModal from "../../components/shared/modals/GeneralConfirmationModal";
import LightboxModal from "../../components/shared/modals/LightboxModal";
import { MoveItemToSpotModal } from "./OffListTab";

type ActiveIndex = string | null;

const OnListTab = ({onList, currentShift, dropId, isDragging}: {
  onList: CreatorListType["onList"], 
  currentShift: DragPosition,
  dropId?: string,
  isDragging?: boolean
}) => {
  
  const {listInfoState} = useContext(CreatorContext);
  const [activeId, setActiveId] = useState<ActiveIndex>(null);
  
  return (
    <div className="list-table">
      {onList.length === 0 && <p className="secondary center subtitle-large absolute padding-top">Nothing to show.</p>}
      <Droppable droppableId={dropId || `on-list`}>
      {(provided, snapshot) => (
        <div 
          ref={provided.innerRef}
          {...provided.droppableProps}
          data-dragging-over={[snapshot.isDraggingOver]}
        >
          {onList.map((item, i) =>
            <Draggable
              key={item?._id || item?.localId || ''}
              draggableId={item?._id || item?.localId || ''}
              index={i}
            >
              {(provided, snapshot) => {
                
                let transform = provided.draggableProps.style?.transform;

                if (snapshot.isDragging && transform) {
                  transform = transform.replace(/\(.+\,/, "(0,");
                }
                const style = {
                  ...provided.draggableProps.style,
                  transform,
                };
                
                let newIndex = i;
                //live update position number while dragging
                if (currentShift && currentShift.destination !== currentShift.source) {

                  const ascending = currentShift.source > currentShift.destination;
                  const difference = currentShift.destination - currentShift.source

                  if (i === currentShift.source) 
                    newIndex = i + difference;
                  else if (ascending) 
                    newIndex = (i >= currentShift.destination && i <= currentShift.source) ? i + 1 : i;
                  else 
                    newIndex = (i >= currentShift.source && i <= currentShift.destination) ? i - 1 : i;
                }
                return <div 
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  data-is-dragging={snapshot.isDragging}
                  style={style}
                >
                  <CreatorListItem
                    listItem={item}
                    activeId={activeId}
                    setActiveId={setActiveId}
                    listItemIndex={dropId ? currentShift?.destination || 0 : newIndex}
                    dragProps={provided.dragHandleProps}
                    controls={
                      <ListItemControls item={item} collapse={() => setActiveId(null)} isUnplaced={!!dropId} />
                    }
                    numbered={isDragging || !dropId && listInfoState?.ordered}
                    checkForPositionConflicts={!dropId}
                  />
                </div>
              }}
            </Draggable>
          )}
          {provided.placeholder}
        </div>
        )}
      </Droppable>
    </div>
  )
}

type ListItemProps = {
  listItem: CreatorListItemType;
  activeId: ActiveIndex;
  setActiveId: Dispatch<SetStateAction<ActiveIndex>>;
  listItemIndex: number;
  controls: React.ReactNode;
  numbered?: boolean;
  dragProps: DraggableProvidedDragHandleProps | null | undefined;
  checkForPositionConflicts?: boolean;
}

export const CreatorListItem = ({listItem, activeId, setActiveId, listItemIndex, controls, numbered, dragProps, checkForPositionConflicts}: ListItemProps) => {

  const {creatorState, moveItemUnplaced} = useContext(CreatorContext);
  const {requestToast} = useContext(ToastContext);
  const itemId = listItem._id || listItem.localId || '';
  
  const [imageModalVisible, setImageModalVisible] = useState(false);
  
  useEffect(() => {
    if (!checkForPositionConflicts) return;
    
    if (listItemIndex + 1 !== listItem.position) {
      requestToast('An item was moved to unplaced after a position conflict.', 'warn');
      moveItemUnplaced(listItem._id || listItem.localId);
    }
  }, []);
  
  return (
    <>
      {imageModalVisible &&
        <LightboxModal closeModal={() => setImageModalVisible(false)} imageUrl={listItem.image} title={listItem.title} />
      }
      <div 
        className={`table-item ${creatorState.on.length - 1 === listItemIndex ? '' : 'bottom-border'}`}
        // data-hero={numbered && listItemIndex === 0}
        data-numbered={!!numbered}
      >
        {numbered && <p className="heading-small weight-light center">{listItemIndex + 1}</p>}
        <div className="item-list">
          <div 
            className="right-item-layout" 
            role={"button"}
            onClick={() => setActiveId(prev => prev === itemId ? null : itemId)}
          >
            <div className="item-row large">
              {listItem?.image &&
                <button className="empty darkness-hover" onClick={(e) => {
                  e.stopPropagation();
                  setImageModalVisible(true);
                }}>
                  <div className="thumb">
                    <img src={listItem.image} />
                  </div>
                </button>
              }
              <div className="item-list">
                <p className="heading-small weight-light">{listItem.title}</p>
                <p className="subtitle-medium">{listItem.subtitle}</p>
              </div>
            </div>
            {dragProps && 
              <div {...dragProps} className="item-row center-horizontal">
                <button onClick={(e) => e.stopPropagation()} className="icon xs drag">
                  <GrabIcon />
                </button>
              </div>
            }
          </div>
        </div>
        <div className="table-item-info-container" aria-current={activeId === itemId}>
          <div>
            <div className="item-list xl table-controls">
              <div className="left-item-layout">
                <div className="item-list min-width">
                  {listItem.link && <p className="subtitle-medium secondary ellipsis">Link</p>}
                  {listItem.authorNotes && <p className="subtitle-medium secondary">Notes</p>}
                </div>
                <div className="item-list min-width">
                  {listItem.link && <p className="subtitle-medium ellipsis">{listItem.link}</p>}
                  {listItem.authorNotes && <p className="subtitle-medium">{listItem.authorNotes}</p>}
                </div>
              </div>
              {controls}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

type ListItemControlProps = {
  item: CreatorListItemType;
  collapse: () => void;
  isUnplaced?: boolean;
}

const ListItemControls = ({item, collapse, isUnplaced}: ListItemControlProps) => {
  
  const {moveItemOff, removeItem, reorderItem} = useContext(CreatorContext);
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isMovingTo, setIsMovingTo] = useState<boolean|string>(false);
  
  const handleReorder = () => {
    if (typeof isMovingTo !== 'string') return;
    reorderItem(item?._id || item?.localId, Number(isMovingTo) - 1);
    setIsMovingTo(false);
  }
  const handleMoveOff = () => {
    moveItemOff(item?._id || item?.localId);
    collapse();
  }
  const handleRemoveItem = () => {
    removeItem(item?._id || item?.localId, isUnplaced ? CREATOR_PAGES.unplaced : CREATOR_PAGES.on);
    collapse();
  }
  
  return (
    <>
      {isEditing && 
        <ListItemCreatorControls page={isUnplaced ? CREATOR_PAGES.unplaced : CREATOR_PAGES.on} editItem={item} handleClose={() => setIsEditing(false)} />
      }
      {isDeleting && 
        <GeneralConfirmationModal setModalVisible={setIsDeleting} onConfirm={handleRemoveItem} confirmText={"Delete Item"} />
      }
      {isMovingTo !== false &&
        <MoveItemToSpotModal isMovingTo={isMovingTo} setIsMovingTo={setIsMovingTo} handleConfirm={handleReorder} />
      }
      <div className="item-row xl">
        <button className="icon-text-vert" onClick={() => setIsEditing(true)}>
          <EditIcon />
          <span className="subtitle-small weight-light">Edit</span>
        </button>
        <button className="icon-text-vert" onClick={() => setIsMovingTo(true)}>
          <VisibleIcon />
          <span className="subtitle-small weight-light">Move To #</span>
        </button>
        <button className="icon-text-vert" onClick={handleMoveOff}>
          <HiddenIcon />
          <span className="subtitle-small weight-light">Move Off</span>
        </button>
        <button className="icon-text-vert" onClick={() => setIsDeleting(true)}>
          <DeleteIcon />
          <span className="subtitle-small weight-light">Delete</span>
        </button>
      </div>
    </>
  )
}

export default OnListTab;