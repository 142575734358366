import { Navigate, Route, Routes } from "react-router";
import Dashboard from "./pages/Dashboard";
import { useEffect, useState } from "react";
import Profile from "./pages/Profile";
import Recommend from "./pages/Recommend";
import UserLists from "./pages/UserLists";
import PublicTab from "./pages/UserLists/PublicTab";
import PersonalTab from "./pages/UserLists/PersonalTab";
import DraftsTab from "./pages/UserLists/DraftsTab";
import LikesTab from "./pages/UserLists/LikesTab";
import Collection from "./pages/Collection";
import List from "./pages/List";
import MainNavigationBar from "./components/navigation/MainNavigationBar";
import HeaderNavigationBar from "./components/navigation/HeaderNavigationBar";
import useThemed from "./hooks/useThemed";
import ListCreator from "./pages/ListCreator";
import ToastContextProvider from "./contexts/ToastContext";
import Settings from "./pages/Settings";
import LoadingIcon from "./components/shared/LoadingIcon";
import { PeakLogo } from "./assets/icons";
import Search from "./pages/Search";
import { setAxiosAuthHeader } from "./api/authInterceptor";
import Media from "./pages/Media";
import Support from "./pages/Support";
import Legal from "./pages/Legal";
import useAuthentication from "./hooks/useAuthentication";

const App = () => {
  
  useThemed();
  const {isLoading, isAuthenticated, getAccessTokenSilently, logout} = useAuthentication();
  
  useEffect(() => {
    const setAxiosDefaults = async () => {
      setAxiosAuthHeader(getAccessTokenSilently, logout);
    }
    isAuthenticated && setAxiosDefaults();
  }, [isAuthenticated, getAccessTokenSilently]);
  
  return (
    <div className="root-inner">
      {isLoading
        ? <SplashScreen />
        : isAuthenticated
          ? <AuthenticatedRouter />
          : <DefaultRouter />
      }
    </div>
  );
}

const DefaultRouter = () => {
  
  return (
    <>
      <Routes>
        <Route path="/" element={<Dashboard auth={false} />} />
        <Route path="login" element={<RedirectToAuth />} />
        <Route path="user/:username" element={<Profile auth={false} />} />
        <Route path="collection/:id" element={<Collection auth={false} />} />
        <Route path="list/:id" element={<List auth={false} />} />
        {GlobalPages()}
      </Routes>
      <GlobalComponents mainNavActive={true} auth={false} />
    </>
  )
}

const RedirectToAuth = () => {
  const { loginWithRedirect } = useAuthentication();
  useEffect(() => {
    loginWithRedirect();
  }, [loginWithRedirect]);
  return null;
}

const AuthenticatedRouter = () => {
  
  const [mainNavActive, setMainNavActive] = useState(true);
  
  return (
    <ContextWrapper>
      <Routes>
        <Route index element={<Dashboard />} />
        <Route path="creator/:id" element={<ListCreator setNavActive={setMainNavActive} />} />
        <Route path='search' element={<Search />} />
        <Route path="recommend" element={<Recommend />} />
        <Route path="profile" element={<Profile />} />
        <Route path="user/:username" element={<Profile />} />
        <Route path="lists" element={<UserLists />}>
          <Route index element={<Navigate to="public" replace />} />
          <Route path="public" element={<PublicTab />} />
          <Route path="personal" element={<PersonalTab />} />
          <Route path="drafts" element={<DraftsTab />} />
          <Route path="likes" element={<LikesTab />} />
        </Route>
        <Route path="edit">
          <Route path="collection/:id" element={<Collection />} />
        </Route>
        <Route path="collection/:id" element={<Collection />} />
        <Route path="list/:id" element={<List />} />
        <Route path="settings" element={<Settings />} />
        <Route path="media" element={<Media />} />
        {GlobalPages()}
      </Routes>
      <GlobalComponents mainNavActive={mainNavActive} auth={true} />
    </ContextWrapper>
  )
}

const GlobalPages = () => {
  return (
    <>
      <Route path="support" element={<Support />} />
      <Route path="privacy" element={<Legal page={'privacy'} />} />
      <Route path="terms" element={<Legal page={'terms'} />} />
      <Route path="*" element={<Navigate to={'/'} />} />
    </>
  )
}

const GlobalComponents = ({mainNavActive, auth}: {mainNavActive: boolean, auth: boolean}) => {
  // should be absolute/fixed positioned elems
  return (
    <>
      <HeaderNavigationBar auth={auth} />
      {mainNavActive && <MainNavigationBar auth={auth} />}
    </>
  )
}

const ContextWrapper = ({children}: {children: React.ReactNode}) => {
  return (
    <ToastContextProvider>
      {children}
    </ToastContextProvider>
  )
}

const SplashScreen = () => {
  return (
    <div className="item-list center-vertical center-horizontal xl full-height">
      <div className="logo"><PeakLogo /></div>
      <LoadingIcon />
    </div>
  )
}

export default App;
