import { useContext, useState } from 'react'
import Modal from '../Modal';
import { CloseIcon, DeleteIcon } from '../../assets/icons';
import CreateListTab from '../createList/CreateListTab';
import { CreatorContext } from '../../contexts/CreatorContext';
import { CreateListForm } from '../createList/CreateListModal';
import useUploadMedia from '../../hooks/useUploadMedia';
import useHandleDeleteList from '../../hooks/useHandleDeleteList';
import { DeleteListModal } from '../shared/cards/ListFeedCard';
import { AuthContext } from '../../contexts/AuthContext';

type Props = {
  setModalVisible: (prev: boolean) => void;
}

const EditListModal = ({setModalVisible}: Props) => {
  
  const {listInfoState: list, list: originalList, setListInfoState} = useContext(CreatorContext);
  const {authUserId} = useContext(AuthContext);
  
  const {handleMedia} = useUploadMedia();
  const {handleConfirmDelete, handleRequestDelete, isConfirmingDelete, handleCancelDelete} = useHandleDeleteList();
  
  const [listForm, setListForm] = useState<CreateListForm>({
    title: list?.title || "",
    description: list?.description || "",
    ascending: list?.ascending === undefined ? true : list.ascending,
    ordered: list?.ordered === undefined ? true : list.ordered,
    category: list?.category || "",
    subCategories: [],
    isPublic: list?.isPublic === undefined ? true : list.isPublic,
    image: list?.image
  });
  const [newImage, setNewImage] = useState<File>();
    
  const isListValid = !!(listForm.title && listForm.description && listForm.category);
  
  const handleUpdateList = async () => {
    if (!isListValid) return
    
    const update = {...listForm};
    
    if (newImage) {
      const newMedia = await handleMedia(newImage);
      if (!newMedia) {
        return;
      }
      Object.assign(update, {image: newMedia.url});
    }
    
    setListInfoState(prev => {
      if (!update.category) return prev;
      return !prev ? prev : ({...prev, ...update, category: update.category})
    });
    setModalVisible(false);
  }
  
  return (
    <>
      {authUserId && isConfirmingDelete && originalList && list &&
        <DeleteListModal
          handleConfirm={() => handleConfirmDelete(originalList._id)}
          handleCancel={handleCancelDelete}
          title={list.title}
          image={originalList?.image}
        />
      }
      <Modal scroll setModalVisible={handleUpdateList}>
        <div className="item-list no-gap">
          <div className='right-item-layout padding remove-padding-bottom center-horizontal'>
            <h1 className="heading-medium">Edit List Settings</h1>
            <button className='icon xs' onClick={handleUpdateList}>
              <CloseIcon />
            </button>
          </div>
          <CreateListTab listId={originalList?._id} form={listForm} setForm={setListForm} setNewImage={setNewImage} forCollection={originalList?.parentCollection} />
          <div className='item-list large top-border padding'>
            <p className='subtitle-large weight-bold'>Danger Zone</p>
            <button className='icon-text alert small' onClick={handleRequestDelete}>
              <DeleteIcon />
              Delete List
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default EditListModal;
