import { useState } from "react";
import Modal from "../../Modal";
import LoadingIcon from "../LoadingIcon";

export type ContextMenuEntry = {
  title: string;
  handleClick: () => void;
  icon?: React.FC;
  disabled?: boolean;
  visible?: boolean;
  alert?: boolean;
  preventCloseOnClick?: boolean;
  promptLoading?: boolean;
};

export type ContextMenuFocusedItem = {
  image: string | null;
  title: string;
  subtitle: string | null;
};

export type ContextMenuContent = {
  title: string;
  focusedItem: ContextMenuFocusedItem | false;
  menuContent: ContextMenuEntry[];
};

type Props = {
  setModalVisible: (prev: boolean) => void;
  menuData: ContextMenuContent;
}

const ContextMenu = ({setModalVisible, menuData}: Props) => {
  
  const [isLoading, setIsLoading] = useState(false);

  const isEmpty = menuData.menuContent.filter(item => item.visible !== false).length === 0;
  
  return (
    <Modal
      setModalVisible={setModalVisible}
      controls
      bottomPane
      modalTitle={menuData.title}
      isLoading={isLoading}
      children={
        <div className="item-list large">
          {menuData.focusedItem &&
            <div className="item-row">
              {menuData.focusedItem.image && 
                <div className="thumb">
                  <img src={menuData.focusedItem.image} />
                </div>
              }
              <div className="item-list small">
                <p className="heading-small weight-light ellipsis-block">{menuData.focusedItem.title}</p>
                {menuData.focusedItem.subtitle && <p className="subtitle-medium secondary ellipsis">{menuData.focusedItem.subtitle}</p>}
              </div>
            </div>
          }
          <div className="item-list">
            {menuData.menuContent.map((item, i) => item.visible !== false &&
              <button aria-disabled={item.disabled} key={i} className={"text menu-entry"} data-alert={item.alert} onClick={() => {
                if (item.disabled) return;
                item.handleClick();
                
                if (item.promptLoading) {
                  setIsLoading(true);
                  return;
                }
                if (item.preventCloseOnClick) return;
                setModalVisible(false);
              }}>
                <div className="item-row">
                  {item.icon && item.icon({})}
                  {item.title}
                </div>
              </button>
            )}
            {isEmpty && <p className="body secondary center">No options</p>}
          </div>
        </div>
      }
    />
  )
}
export default ContextMenu;