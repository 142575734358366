import {useContext, useEffect, useRef, useState } from "react";
import LightboxModal from "./LightboxModal";
import { useDeleteMedia, useGetUserMedia } from "../../../api/data-access";
import Modal from "../../Modal";
import UploadInput from "../inputs/UploadInput";
import { CloseIcon, DeleteIcon, OptionsVerticalIcon, ReportIcon, VisibleIcon } from "../../../assets/icons";
import { fallbackImage } from "../../../constants/urls";
import { FilterButtons } from "../../../pages/Dashboard";
import LoadingContainer from "../LoadingContainer";
import { Media } from "../../../types/SharedTypes";
import ContextMenu, { ContextMenuContent } from "./ContextMenu";
import { basicDateFormat } from "../../../tools/Helpers";
import GeneralConfirmationModal from "./GeneralConfirmationModal";
import useDisplayServerError from "../../../hooks/useDisplayServerError";
import { ToastContext } from "../../../contexts/ToastContext";

type Props = {
  clearPreviewImage: () => void;
  setNewImage: (value: string | undefined) => void;
  setModalVisible: (prev: boolean) => void;
  handleImage: (file: File) => void;
  postId?: string;
  imagePreview?: string;
  originalImage?: string;
  currentImage?: string;
  closeOnSelect?: boolean;
}

const MediaSelectorModal = ({postId, imagePreview, clearPreviewImage, setNewImage, originalImage, currentImage, setModalVisible, handleImage, closeOnSelect}: Props) => {

  const [showLightbox, setShowLightbox] = useState<string|false>(false);
  const [currentFilter, setCurrentFilter] = useState<string>('relevant');
  const {isSuccess, isLoading, data: userMedia} = useGetUserMedia(currentFilter === 'relevant' ? postId : undefined);

  const scrollRef = useRef<HTMLDivElement>(null);

  const handleSelectImage = () => {
    if (!showLightbox) return;
    setNewImage(showLightbox);
    if (imagePreview) clearPreviewImage();
    setShowLightbox(false);
    if (scrollRef.current) scrollRef.current.scrollTo(0,0);
    if (closeOnSelect) setModalVisible(false);
  }
  const handleSelectNullImage = () => {
    setNewImage(undefined);
    if (imagePreview) clearPreviewImage();
    if (closeOnSelect) setModalVisible(false);
  }

  const clearImage = () => {
    if (!imageHasChanged) return;
    if (imagePreview) clearPreviewImage();
    else setNewImage(originalImage);
  }

  const imageHasChanged = !!imagePreview || originalImage !== currentImage;

  return (
    <>
      {showLightbox &&
        <LightboxModal 
          closeModal={() => setShowLightbox(false)}
          imageUrl={showLightbox}
          actionButton={
            <button className="primary" onClick={handleSelectImage}>{"Use this image"}</button>
          }
        />
      }
      <Modal
        setModalVisible={setModalVisible}
        modalTitle={"Media Selector"}
        scroll
        bottomPane
        fullHeightBottomPane
        controls
        removePadding
        scrollRef={scrollRef}
      >
        <div className="item-row large padding">
          <div className='thumb large'>
            <img src={imagePreview || currentImage || fallbackImage} />
          </div>
          <div className="item-list align-start">
            <p className="heading-small">{(imagePreview || currentImage) ? "Current Selected Image" : "No Current Image"}</p>
            {!currentImage && originalImage && <p className="body secondary">Image will be set automatically.</p>}
            {imageHasChanged &&
              <button className="icon-text xxs subtle tiny" onClick={clearImage}>
                <CloseIcon />
                Reset
              </button>
            }
          </div>
        </div>
        <div className="item-list xl top-border secondary-border padding">
          <UploadInput
            title={'Select Image'}
            subtitle={"Drop File or Click Here"}
            hero
            uniqueID={'newImageUpload'}
            mimeTypes={['image/png', 'image/jpeg']}
            getFiles={(files) => handleImage(files[0])}
          />
          <div className="item-row xl">
            <p className="heading-small">Your Media</p>
            {postId && <FilterButtons
              selected={currentFilter}
              filters={[{label: "Relevant", value: 'relevant'}, {label: 'All', value: "all"}]}
              preventDeselect
              onClick={(value) => setCurrentFilter(value)}
              removePadding
              small
            />}
          </div>
          {isLoading && <LoadingContainer />}
          {isSuccess &&
            <div className="item-grid-wrap small">
              <button className="empty darkness-hover" onClick={handleSelectNullImage}>
                <div className='thumb full square item-list center-vertical center-horizontal position-relative' data-outline={!imagePreview && !currentImage}>
                  <div className="tag small position-absolute">
                    <p className="subtitle-medium weight-light center">Remove Image</p>
                  </div>
                  <img src={fallbackImage} />
                </div>
              </button>
              {userMedia.map(media =>
                <MediaTile onClick={() => setShowLightbox(media.url)} media={media} outline={!imagePreview && media.url === currentImage} />
              )}
            </div>
          }
          {isSuccess && userMedia.length === 0 &&
            <p className="body secondary center">
              {currentFilter === "all" ? "No images to show" : "No relevant images"}
            </p>
          }
        </div>
      </Modal>
    </>
  )
}

type MediaTileProps = {
  media: Media;
  onClick?: () => void;
  detail?: boolean;
  outline?: boolean;
}

export const MediaTile = ({media, onClick, outline, detail}: MediaTileProps) => {

  const {requestToast} = useContext(ToastContext);

  const deleteMedia = useDeleteMedia();
  const {handleError} = useDisplayServerError();

  const [optionsVisible, setOptionsVisible] = useState(false);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const menuData: ContextMenuContent = {
    title: "Media Options",
    focusedItem: {
      image: media.url,
      title: media.originalFileName,
      subtitle: basicDateFormat(media.dateCreated)
    },
    menuContent: [
      {
        title: "View Media",
        icon: VisibleIcon,
        handleClick: onClick || (() => null),
        visible: !!onClick
      },
      {
        title: "Delete Media",
        icon: DeleteIcon,
        alert: true,
        handleClick: () => setDeleteVisible(true)
      }
    ]
  }

  const handleDeleteMedia = async () => {
    setDeleteLoading(true);
    try {
      const force = deleteMedia.isSuccess && deleteMedia.data.canBeForced;
      const res = await deleteMedia.mutateAsync({mediaId: media._id, force});
      if (res.success) {
        requestToast("Media deleted successfully.", 'success');
        setDeleteVisible(false);
      } else {
        requestToast("Media is being used.", 'warn');
      }
    } catch (err) {
      handleError(err);
    }
    setDeleteLoading(false);
  }

  useEffect(() => {
    if (!deleteVisible && deleteMedia.data) deleteMedia.reset();
  }, [deleteVisible]);

  const MediaResults = () => {
    if (deleteMedia.isSuccess && 
      (deleteMedia.data.usedIn.includes('pfp') || deleteMedia.data.usedIn.includes('banner'))
    ) return <p className="body">This media is being used in your profile images. Update your profile in order to delete this media.</p>;
    else if (deleteMedia.isSuccess) return <p className="body">This media is used in some lists. Do you want to delete it anyway?</p>;
    return <p className="body">This action cannot be undone. Delete media?</p>;
  }

  return (
    <>
      {deleteVisible &&
        <GeneralConfirmationModal 
          setModalVisible={setDeleteVisible}
          onConfirm={handleDeleteMedia}
          alert
          isLoading={deleteLoading}
          confirmText={"Delete Media"}
          disableConfirm={deleteMedia.isSuccess && !deleteMedia.data.canBeForced}
          children={
            <div className="item-list large">
              <div 
                data-alert={deleteMedia.isSuccess && !deleteMedia.data.success && !deleteMedia.data.canBeForced}
                data-warn={deleteMedia.isSuccess && !deleteMedia.data.success && deleteMedia.data.canBeForced}
                className="item-row tile padding-small"
              >
                <div className="icon-display small">
                  <ReportIcon />
                </div>
                <MediaResults />
              </div>
              <div className="item-row">
                <div className="thumb square small">
                  <img src={media.url} />
                </div>
                
                <div className="item-list small">
                  <h3 className="heading-small">{media.originalFileName}</h3>
                  <p className="body secondary">{basicDateFormat(media.dateCreated)}</p>
                </div>
              </div>
            </div>
          }
        />
      }
      {optionsVisible &&
        <ContextMenu
          setModalVisible={setOptionsVisible}
          menuData={menuData}
        />
      }
      <div className="item-list">
        <button key={media._id} className="empty darkness-hover" onClick={onClick}>
          <div className='thumb full square' data-outline={outline}>
            <img src={media.url} />
          </div>
        </button>
        {detail &&
          <div className="position-absolut right-item-layout">
            <p className="body ellipsis-block">{media.originalFileName}</p>
            <button className="icon xs escape-padding" onClick={() => setOptionsVisible(true)}>
              <OptionsVerticalIcon />
            </button>
          </div>
        }
      </div>
    </>
  )
}

export default MediaSelectorModal;