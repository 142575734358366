import PageWrapper from "./PageWrapper";
import { ArrowHeadLeftIcon, ArrowHeadRightIcon } from "../assets/icons";
import { useEffect, useRef, useState } from "react";
import Modal from "../components/Modal";
import LoadingIcon from "../components/shared/LoadingIcon";
import { categories } from "../assets/categories";
import { useSearchLists, useSearchUsers } from "../api/data-access";
import FeedCard from "../components/shared/cards/FeedCard";
import SearchBar from "../components/shared/SearchBar";
import useHideHeader from "../hooks/useHideHeader";
import { Link, useSearchParams } from "react-router-dom";
import { PopulatedListPostType, User } from "../types/SharedTypes";
import { FilterButtons, FilterModal } from "./Dashboard";
import { UserItem } from "../components/shared/modals/FollowsModal";

type SearchCollections = 'lists'|'users';

const Search = () => {
  
  const [searchParams] = useSearchParams();
  const initialQuery = useRef(searchParams.get('initialQuery'));
  useHideHeader();
  
  const [selectedCollection, setSelectedCollection] = useState<SearchCollections>('lists');
  const [selectedFilter, setSelectedFilter] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState(initialQuery.current || '');
  const [rawSearchQuery, setRawSearchQuery] = useState(initialQuery.current || '');
  
  const {data: listsRes, isError: listsErr, isPending: listsPend, isSuccess: listsSuc} = useSearchLists(
    {query: searchQuery, category: selectedFilter || undefined}, 
    (searchQuery.length > 2 && selectedCollection === 'lists')
  );
  const {data: usersRes, isError: usersErr, isPending: usersPend, isSuccess: usersSuc} = useSearchUsers(
    {query: searchQuery}, 
    (searchQuery.length > 2 && selectedCollection === 'users')
  );
  
  const isList = selectedCollection === 'lists';
  const results = isList ? listsRes : usersRes;
  const isError = isList ? listsErr : usersErr;
  const isPending = isList ? listsPend : usersPend;
  const isSuccess = isList ? listsSuc : usersSuc;
  
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  
  const handleSearch = () => {
    setSearchQuery(rawSearchQuery);
  }
  
  useEffect(() => {
    if (rawSearchQuery.length < 3) return;
    handleSearch();
  }, [selectedCollection]);
  
  if (isError) return <p>Error</p>;
  return (
    <PageWrapper>
      {filterModalVisible && 
        <FilterModal selected={selectedFilter} setSelected={(val) => setSelectedFilter(val)} setModalVisible={setFilterModalVisible}  />
      }
      <div className="item-list large">
        <SearchBar searchQuery={rawSearchQuery} setSearchQuery={setRawSearchQuery} onSubmit={handleSearch} />
        <div className="item-row large">
          <div className="item-list no-gap">
            <p className="subtitle-small indent">Search for</p>
            <FilterButtons 
              filters={[{value: 'lists', label: 'Lists'}, {value: 'users', label: 'Users'}]} 
              onClick={(val) => setSelectedCollection(val as SearchCollections)} 
              selected={selectedCollection} 
              preventDeselect 
            />
          </div>
          {isList && <>
            <div className="divider-line-vertical" />
            <div className="item-list no-gap">
              <p className="subtitle-small indent">Category</p>
              <div className="right-item-layout center-horizontal no-gap">
                <FilterButtons 
                  filters={categories} 
                  onClick={(val) => setSelectedFilter(val)} 
                  selected={selectedFilter} 
                  escapePadding 
                  gradientOverflow 
                />
                <div className="height-80 item-row small">
                  <div className="full-height left-border secondary-border" />
                  <button className="icon xs" onClick={() => setFilterModalVisible(true)}>
                    <ArrowHeadRightIcon />
                  </button>
                </div>
              </div>
            </div>
          </>
          }
        </div>
        
        {isPending && 
          <div className="item-row center-horizontal padding">
            <LoadingIcon />
          </div>
        }
        {isSuccess && results && 
          <section className="item-list large">
            {results.length === 0 && <p className="subtitle-large secondary center">Nothing to show.</p>}
            {results.map((item) => {
              if (isList) return <FeedCard key={item._id} post={item as PopulatedListPostType} />;
              const user = item as User;
              return <UserItem key={item._id} user={user} />;
            })}
          </section>
        }
      </div>
    </PageWrapper>
  )
}

export default Search;