import { Link } from "react-router-dom";
import { OptionsHorizontalIcon } from "../../../assets/icons";
import { MouseEvent } from "react";
import { getCategory } from "../../../assets/categories";
import { Categories, CollectionPeriods, User } from "../../../types/SharedTypes";
import { basicDateFormat } from "../../../tools/Helpers";
import useAuthentication from "../../../hooks/useAuthentication";

type Props = {
  handleContextMenu: (e: MouseEvent<HTMLButtonElement>) => void;
  badgeBanner?: React.ReactNode;
  hideHeader?: boolean;
  small?: boolean;
  isPinned?: boolean;
  isInFeed?: boolean;
  themedHeader?: boolean;
  themeOverride?: any;
  preventLink?: boolean;
  handleClick?: () => void;
}

type DataProps = {
  author: User | string;
  isDraft?: boolean;
  createdAt: string;
  thumbImage?: string;
  link: string;
  entriesLength: number;
  entriesTerm: string;
  title: string;
  category: Categories;
  type: "List" | "Collection";
  isAutoGenerated?: boolean;
  period?: CollectionPeriods;
}

const FeedCardBody = (props: Props & DataProps) => {

  const {handleContextMenu, badgeBanner, hideHeader, small, themedHeader, isPinned, isInFeed = true, themeOverride, preventLink, handleClick} = props;
  const {author, isDraft, createdAt, thumbImage, link, entriesLength, entriesTerm, title, category, type, isAutoGenerated, period} = props;
  
  return (
    <>
      <div className="card-outer">
        {badgeBanner && 
          <div className="badge-banner">
            {badgeBanner}
          </div>
        }
        <div 
          className="card" 
          style={themeOverride} 
          data-themed={true} 
          data-hide-header={hideHeader || small} 
          data-small={small}
          data-draft={isDraft}
          data-themed-header={themedHeader}
        >
          {!hideHeader && author && typeof author !== "string" &&
            <Link to={`/user/${author.username}`}>
              <article className="item-row center-vertical card-header">
                <div className={`user-avatar ${themedHeader ? 'small' : ''}`}>
                  <img src={author?.picture} />
                </div>
                <div className={`item-list ${themedHeader ? 'med' : ''}`}>
                  <div className="item-row">
                    <p className={themedHeader ? "subtitle-large weight-bold" : 'heading-small'}>{author.displayName}</p>
                    <p className={"subtitle-medium secondary"}>{`@${author.username}`}</p>
                  </div>
                  <p className="subtitle-medium secondary">{isInFeed ? `${period ? 'Updated' : 'Created'} ${type}` : basicDateFormat(createdAt)}</p>
                </div>
              </article>
            </Link>
          }
          <LinkWrapper prevent={preventLink} link={link} handleClick={handleClick}>
            <div className="item-row no-gap full-height">
              {thumbImage && 
                <div className="card-thumb">
                  <img src={thumbImage} />
                </div>
              }
              {small 
                ? <SmallCardBody type={type} entriesLength={entriesLength} entriesTerm={entriesTerm} title={title} category={category} handleContextMenu={handleContextMenu} isAutoGenerated={isAutoGenerated} period={period} /> 
                : <NormalCardBody type={type} entriesLength={entriesLength} entriesTerm={entriesTerm} title={title} category={category} handleContextMenu={handleContextMenu} isAutoGenerated={isAutoGenerated} period={period} />
              }
            </div>
          </LinkWrapper>
        </div>
      </div>
    </>
  )
}
export default FeedCardBody;

const LinkWrapper = ({prevent, children, link, handleClick}: {
  prevent?: boolean;
  handleClick?: () => void;
  children: React.ReactNode; 
  link: string
}) => {
  if (handleClick) return (
    <button onClick={handleClick} className="empty">
      {children}
    </button>
  )
  else if (prevent) return <>{children}</>;
  return (
    <Link to={link}>
      {children}
    </Link>
  )
}

type CardBody = {
  entriesLength: number;
  entriesTerm: string;
  title: string;
  category: Categories;
  handleContextMenu: (e: MouseEvent<HTMLButtonElement>) => void;
  type: "Collection" | "List";
  isAutoGenerated?: boolean;
  period?: CollectionPeriods;
}

const SmallCardBody = ({title, category, entriesLength, entriesTerm, handleContextMenu, type, isAutoGenerated, period}: CardBody) => {
  
  const {isAuthenticated} = useAuthentication();
  
  return (
    <div className="card-inner min-width">
      <div className="item-list">
        <div className="item-row med">
          <div className="tag" data-themed={true}>
            <p className="subtitle-medium header">{getCategory(category)}</p>
          </div>
          {period &&
            <div className="tag" data-themed={true}>
              <p className="subtitle-medium header">{period}</p>
            </div>
          }
        </div>
        <p className="subtitle-medium secondary">
          {type}
          <span className="secondary"> | </span>
          {entriesLength !== undefined ? entriesLength : 'Unknown'} {entriesTerm}
        </p>
        <div className="right-item-layout center-horizontal">
          <p className="heading-small ellipsis-block weight-light">{title}</p>
          {isAuthenticated && !isAutoGenerated && <button className="icon xsmall" onClick={handleContextMenu}><OptionsHorizontalIcon /></button>}
        </div>
      </div>
    </div>
  )
}
const NormalCardBody = ({title, category, entriesLength, entriesTerm, handleContextMenu, type, isAutoGenerated, period}: CardBody) => {
  
  const {isAuthenticated} = useAuthentication();
  
  const titleIsLong = title.length > 60;
  
  return (
    <div className="bottom-item-layout card-inner">
      <div className="item-list">
        <div className="item-row med">
          <div className="tag" data-themed={true}>
            <p className="subtitle-medium header">{getCategory(category)}</p>
          </div>
          {period &&
            <div className="tag" data-themed={true}>
              <p className="subtitle-medium header">{period}</p>
            </div>
          }
        </div>
        <p className={`${titleIsLong ? 'heading-medium' : 'heading-large'} ellipsis-block`} data-clamp={3}>{title}</p>
      </div>
      <div className="right-item-layout center-horizontal">
        <p className="subtitle-medium">
          {type}
          <span className="secondary"> | </span>
          {entriesLength !== undefined ? entriesLength : 'Unknown'} {entriesTerm}
        </p>
        {isAuthenticated && !isAutoGenerated && <button className="icon xsmall" onClick={handleContextMenu}><OptionsHorizontalIcon /></button>}
      </div>
    </div>
  )
}